import { useEffect, useState } from 'react';

/**
 * Debounce any fast changing value
 *
 * @param {object|string|number} value
 * @param {number} delay
 * @param {Function|null} callback
 * @returns {object|string|number}
 */
export default function useDebounce(value, delay, callback) {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      if (callback instanceof Function) {
        callback(value);
      }
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value]);
  return debouncedValue;
}
